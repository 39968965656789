import {isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js';
import every from 'lodash/every';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import head from 'lodash/head';
import includes from 'lodash/includes';
import initial from 'lodash/initial';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import map from 'lodash/map';
import range from 'lodash/range';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import slice from 'lodash/slice';
import tail from 'lodash/tail';
import zip from 'lodash/zip';
import {DateTime} from 'luxon';
import {useEffect, useRef, useState} from 'react';
import {Nav} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';
import {FaBackspace, FaCalendar, FaCalendarAlt, FaCalendarCheck, FaChild, FaHashtag, FaHeartBroken, FaPhone, FaSms, FaTeeth, FaTooth} from 'react-icons/fa';
import {GiPincers, GiTreeRoots} from 'react-icons/gi';
import {IoDiamond} from 'react-icons/io5';
import {TiBusinessCard} from 'react-icons/ti';
import InfiniteScroll from 'react-infinite-scroll-component';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import {useInfiniteQuery, useMutation} from 'react-query';
import Basia from './Basia.webp';
import Gosia from './Gosia.webp';
import Kasia from './Kasia.webp';
import Milosz from './Milosz.webp';
import Olga from './Olga.webp';
import Agata from './Agata.webp';


export const IS_DEV = process.env.NODE_ENV !== 'production';
const BASE_URL = IS_DEV ? 'http://localhost:8008/api' : 'https://umow.floraldent.pl/api';

const SLOTS_URL = `${BASE_URL}/slots`;
const VISITS_URL = `${BASE_URL}/visits`;
const CONFIRMATIONS_URL = `${BASE_URL}/confirmations`;
const CODES_URL = `${BASE_URL}/code-requests`;

export async function getDataOrThrow(resp) {
    const ok = resp.ok;
    let data;
    try {
        data = await resp.json();
        if (!ok)
            throw Error(data?.message ?? data?.detail ?? data);
        return data;
    } catch (ex) {
        if (!ok) {
            throw Error(data?.message ?? data?.detail ?? await data.text());
        } else {
            throw ex;
        }
    }
}

function TimeSlotButton({startAt, ...props}) {
    return <Button variant="outline-success" size="sm" className="text-center px-0 my-auto" {...props}>
        <span className="mx-1 align-middle">{startAt}</span>
    </Button>;
}

export const SERVICE_IDS = {ADULT: "13499", CHILD: "13497", ORTO: "16909", TEETH_CLEANING: "13505", RCT: "21954", EXTRACTION: '21659'};

export const SERVICES = {
    [SERVICE_IDS.ADULT]: {Icon: FaTooth, name: "Dorosły", duration: 2},
    [SERVICE_IDS.CHILD]: {Icon: FaChild, name: "Dziecko", duration: 2},
    [SERVICE_IDS.ORTO]: {Icon: FaTeeth, name: "Ortodoncja", duration: 1},
    [SERVICE_IDS.RCT]: {Icon: GiTreeRoots, name: "Kanałowe", duration: 4},
    [SERVICE_IDS.TEETH_CLEANING]: {Icon: IoDiamond, name: "Higienizacja", duration: 3},
    [SERVICE_IDS.EXTRACTION]: {Icon: GiPincers, name: "Chirurgia", duration: 2}
};

export const BUTTONS_ORDER = [SERVICE_IDS.ADULT, SERVICE_IDS.CHILD, SERVICE_IDS.TEETH_CLEANING, SERVICE_IDS.ORTO, SERVICE_IDS.EXTRACTION, SERVICE_IDS.RCT];

const DOCTORS = {
    Olga: {src: Olga, name: "lek. dent. Olga Bednarek", bg: "info", services: [SERVICE_IDS.ADULT, SERVICE_IDS.CHILD, SERVICE_IDS.ORTO]},
    Kasia: {src: Kasia, name: "lek. dent. Katarzyna Rajkowska", bg: "success", services: [SERVICE_IDS.ORTO]},
    Gosia: {src: Gosia, name: "higienistka Małgorzata Mutton", bg: "light", text: "dark", services: [SERVICE_IDS.TEETH_CLEANING]},
    Milosz: {src: Milosz, name: "lek. dent. Miłosz Błaszczak", bg: "dark", services: [SERVICE_IDS.ADULT, SERVICE_IDS.CHILD, SERVICE_IDS.RCT]},
    Basia: {src: Basia, name: "lek. dent. Barbara Wojciechowska", bg: "danger", services: [SERVICE_IDS.EXTRACTION, SERVICE_IDS.ADULT]},
    Agata: {src: Agata, name: "lek. dent. Agata Wagner", bg: "success", services: [SERVICE_IDS.ADULT, SERVICE_IDS.CHILD, SERVICE_IDS.ORTO]},
};

function DoctorAvatar({doctor, ...props}) {
    const {src, name} = DOCTORS[doctor];
    return <Image src={src} roundedCircle {...props} alt={name} title={name}/>;
}

function DoctorDay({date, doctor, service, slots, selectSlot, first, withBadge}) {
    if (isEmpty(slots)) return null;

    const {duration} = SERVICES[service];

    function isContinuous(slotsChunk) {
        if (duration === 1)
            return true;
        if (size(slotsChunk) !== duration)
            return false;
        const prev = initial(slotsChunk),
            next = tail(slotsChunk);
        return every(zip(prev, next), ([[, endAt], [startAt]]) => (endAt === startAt));
    }

    const slotsChunks = map(range(size(slots)), (start) => slice(slots, start, start + duration)),
        longEnoughSlots = map(filter(slotsChunks, isContinuous), (merged) => ([head(merged)[0], last(merged)[1]])),
        notOverlappingSlots = reduce(longEnoughSlots, (filtered, [startAt, endAt]) => {
            const [, lastEndAt] = last(filtered) ?? ["", "00:00"];
            return startAt >= lastEndAt ? [...filtered, [startAt, endAt]] : filtered;
        }, []);

    return <Stack direction="horizontal" gap={1} className="d-flex flex-no-wrap flex-shrink-1 flex-grow-1 pb-1" style={!first ? {borderTop: 'solid 1px lightgray'} : {}}>
        <DoctorAvatar doctor={doctor} width={64} className="me-1 pt-1"/>
        <Stack direction="vertical" className="py-0" gap={1}>
            <small><DoctorBadge doctorData={[doctor]}/></small>
            <Stack direction="horizontal" gap={1} className="d-flex flex-wrap flex-shrink-1 flex-grow-1">
                {map(notOverlappingSlots, ([startAt, endAt], idx) =>
                    <TimeSlotButton key={idx} startAt={startAt} onClick={() => selectSlot({dateFrom: date, doctor, startAt, endAt})}/>)}
            </Stack>
        </Stack>
    </Stack>;
}

function DoctorBadge({doctorData: [doctor]}) {
    const {name, bg = 'info', text} = DOCTORS[doctor] ?? {};
    return <Badge bg={bg} text={text} pill>{name}</Badge>;
}

function Day({date, doctors, service, selectSlot}) {
    const availDoctors = filter(doctors, ([doctor, slots]) => !isEmpty(slots) && includes(DOCTORS[doctor]?.services, service));
    if (isEmpty(availDoctors)) return null;
    return <Row><Card className="px-0">
        <Card.Header className="py-1">{date.setLocale('pl').toFormat('cccc - d LLLL')}</Card.Header>
        <Card.Body className="px-2 py-0">
            {map(availDoctors,
                ([doctor, slots], idx) =>
                    <DoctorDay key={doctor} {...{doctor, date, service, slots}} selectSlot={selectSlot} first={!idx} withBadge={size(availDoctors) > 1}/>
            )}
        </Card.Body>
    </Card></Row>;
}

export function SpinningLoader() {
    // Fixed height (more than 2rem of the spinner) is necessary to avoid shaking scroll when spinner spins.
    return <div className="w-100 d-flex justify-content-center" style={{height: "3rem"}}>
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Ładowanie...</span>
        </Spinner>
    </div>;
}

function InfiniteScrollImproved({scrollableTarget, hasMore, isFetching, next, children, allowAutoFetch, ...props}) {
    useEffect(() => {
        const notFullHeightYet = ({scrollHeight, clientHeight}) => {
            return scrollHeight <= clientHeight;
        };
        const shouldFetch = allowAutoFetch && notFullHeightYet(document.getElementById(scrollableTarget));
        hasMore && !isFetching && shouldFetch && next();
    }, [allowAutoFetch, hasMore, isFetching, next, scrollableTarget]);

    return <InfiniteScroll {...props} {...{scrollableTarget, hasMore, next}} >{children}</InfiniteScroll>;
}

export function VisitSlots({service, selectSlot, selectService}) {
    async function queryFn({pageParam = 1}) {
        const resp = await fetch(`${SLOTS_URL}/${pageParam - 1}`),
            data = await resp.json();

        return map(data, ([date, doctors]) => ({date: DateTime.fromISO(date), doctors}));
    }

    const getNextPageParam = (lastPage, allPages) => (size(allPages) + 1);

    const {data, hasNextPage, fetchNextPage, isFetching} = useInfiniteQuery(['slots'], {queryFn, getNextPageParam}),
        {pages = []} = data ?? {};

    const items = flatten(pages),
        days = map(items, ({date, doctors}, idx) =>
            <Day key={idx} date={date} doctors={doctors} service={service} selectSlot={selectSlot}/>),
        lastDay = last(items),
        allowAutoFetch = !lastDay?.date || lastDay.date.diff(DateTime.now(), 'days').as('days') < 56;

    return <Container className="text-center d-flex flex-column h-100 px-1">
        <Nav variant="pills" fill activeKey={service} onSelect={selectService} className="widget-nav small">
            {map(BUTTONS_ORDER, (id) => {
                const {Icon, name} = SERVICES[id];
                return <Nav.Item key={id}>
                    <Nav.Link eventKey={id} className="px-0"><Icon size="1.5em"/> {name}</Nav.Link>
                </Nav.Item>;
                // return <ToggleButton className="flex-grow-1 px-1 small" key={id} id={id} variant="outline-success" size="sm" value={id}><Icon/> {name}</ToggleButton>;
            })}
        </Nav>
        <Container fluid className="flex-grow-1 overflow-scroll px-0" id="slots-target-container">
            <InfiniteScrollImproved dataLength={days.length} next={() => fetchNextPage()} loader={<SpinningLoader/>}
                                    hasMore={hasNextPage !== false  /* undefined when loading 1st page */} isFetching={isFetching} allowAutoFetch={allowAutoFetch}
                                    className="mx-0 w-100 container" diff={lastDay?.date && lastDay.date.diff(DateTime.now(), 'days')}
                                    scrollableTarget="slots-target-container">
                {days}
            </InfiniteScrollImproved>
        </Container>
    </Container>;
}

export function VisitDetails({dateFrom, doctor, startAt, endAt, service, firstName = '', lastName = '', info = '', phone, setDetails, submitVisit, changeSlot}) {
    const [validated, setValidated] = useState(false),
        [phoneValid, setPhoneValid] = useState();  // workaround for messy validation of PhoneInput

    async function mutationFn() {
        const {countryCallingCode, nationalNumber} = parsePhoneNumber(phone, 'PL') ?? {};
        const body = JSON.stringify({
            firstName, lastName, prefix: `+${countryCallingCode}`, phone: nationalNumber, info, service,
            startAt, endAt, dateFrom: dateFrom.toISODate()
        });
        const resp = await fetch(`${VISITS_URL}/${doctor}`, {method: 'POST', body, headers: {'Content-Type': 'application/json'}}),
            json = await getDataOrThrow(resp);
        return json;
    }

    const {mutateAsync: bookVisit, isLoading: spinning, isError} = useMutation({mutationFn});

    const onSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const {currentTarget: form} = event;
        if (form.checkValidity()) {
            const {jwtToken, customerId} = await bookVisit();
            submitVisit(jwtToken, 1, customerId);
        }
        setValidated(true);
    };

    const phoneRef = useRef(),
        invalidCls = phoneValid === undefined ? 'border-0' : 'border-1 red';

    return <div className="modal show d-block">
        <Modal.Dialog>
            <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Modal.Header>
                    <VisitTitle {...{service, dateFrom, startAt, doctor}} />
                </Modal.Header>
                <Modal.Body>
                    {isError && <Alert variant="danger"><FaHeartBroken/> Błąd rezerwacji terminu. Spróbuj proszę jeszcze raz.</Alert>}
                    <InputGroup hasValidation>
                        <InputGroup.Text><TiBusinessCard/></InputGroup.Text>
                        <Form.Control required type="text" autoFocus autoComplete="given-name" value={firstName} placeholder="Imię pacjenta"
                                      onChange={({target}) => setDetails({firstName: target.value})} name="firstName"/>
                        <Form.Control required type="text" autoComplete="family-name" value={lastName} placeholder="Nazwisko pacjenta"
                                      onChange={({target}) => setDetails({lastName: target.value})} name="lastName"/>
                    </InputGroup>
                    <InputGroup hasValidation>
                        <InputGroup.Text><FaPhone/></InputGroup.Text>
                        <PhoneInput country="pl" value={phone}
                                    className={`form-control`} placeholder="+48"
                                    inputProps={{
                                        required: true, className: `form-control py-1 w-100 ${invalidCls}`,
                                        ref: phoneRef, name: 'phone'
                                    }}
                                    preferredCountries={['pl', 'ua', 'de']}
                                    onChange={(phone, country) => {
                                        const ret = isValidPhoneNumber(phone, country?.countryCode?.toUpperCase?.() ?? 'PL');
                                        setPhoneValid(ret);
                                        setDetails({phone});
                                        phoneRef.current?.setCustomValidity?.(ret ? "" : "Podaj poprawny numer telefonu.");
                                        return ret;
                                    }}
                        />
                    </InputGroup>
                    <Form.Control as="textarea" rows={2} placeholder="O co chodzi?" value={info} onChange={({target}) => setDetails({info: target.value})}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="me-auto" variant="outline-dark" onClick={changeSlot}><FaBackspace/> Inny termin</Button>
                    <Button variant="success" type="submit" disabled={spinning}>{spinning ? <Spinner as="span" animation="border" size="sm"/> : <FaCalendarCheck/>} Umów wizytę</Button>
                </Modal.Footer>
            </Form>
        </Modal.Dialog>
    </div>;
}

export function Confirmation({doctor, service, dateFrom, startAt, codeNumber, jwtToken, changeSlot, confirmVisit, setNewToken}) {
    const [validated, setValidated] = useState(),
        [smsCode, setSmsCode] = useState('');  // It is one-time, so not in the reducer.

    async function confirmSmsMutationFn() {
        const body = JSON.stringify({smsCode, jwtToken});
        const resp = await fetch(CONFIRMATIONS_URL, {method: 'POST', body, headers: {'Content-Type': 'application/json'}}),
            json = await getDataOrThrow(resp);
        return json;
    }

    const {mutateAsync: confirmSms, isLoading: spinning, isError} = useMutation({mutationFn: confirmSmsMutationFn});

    async function resendCodeMutationFn() {
        const body = JSON.stringify({jwtToken});
        const resp = await fetch(CODES_URL, {method: 'POST', body, headers: {'Content-Type': 'application/json'}}),
            json = await getDataOrThrow(resp);
        return json;
    }

    const {mutateAsync: resendCode, isLoading: resending} = useMutation({mutationFn: resendCodeMutationFn});

    const onSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const {currentTarget: form} = event;
        if (form.checkValidity()) {
            const {detail} = await confirmSms();
            confirmVisit(detail);
        }
        setValidated(true);
    };

    async function requestAnotherCode() {
        const {jwtToken, codeNumber} = await resendCode();
        setNewToken(jwtToken, codeNumber);
    }

    return <div className="modal show d-block">
        <Form noValidate autoComplete="off" onSubmit={onSubmit} validated={validated}>
            <Modal.Dialog>
                <Modal.Header><strong>Potwierdź wizytę otrzymanym kodem SMS</strong></Modal.Header>
                <Modal.Body>
                    {isError && <Alert variant="danger"><FaHeartBroken/> Błąd. Spróbuj proszę jeszcze raz.</Alert>}
                    <InputGroup>
                        <InputGroup.Text><FaHashtag className="me-2"/> Kod nr {codeNumber}</InputGroup.Text>
                        <Form.Control type="text" required size="lg" autoFocus
                                      value={smsCode} onChange={({target}) => setSmsCode(target.value)}/>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" size="sm" onClick={changeSlot}><FaBackspace/> Inny termin</Button>
                    <Button className="me-auto" size="sm" variant="outline-dark" onClick={requestAnotherCode} disabled={resending}>
                        {resending ? <Spinner as="span" animation="border" size="sm"/> : <FaSms/>} Wyślij nowy kod
                    </Button>
                    <Button variant="success" type="submit" disabled={spinning}>
                        {spinning ? <Spinner as="span" animation="border" size="sm"/> : <FaCalendarCheck/>} Umów wizytę
                    </Button>
                </Modal.Footer>
                <Modal.Footer>
                    <VisitTitle {...{service, dateFrom, startAt, doctor}} />
                </Modal.Footer>
            </Modal.Dialog>
        </Form>
    </div>;
}

function VisitTitle({service, dateFrom, startAt, doctor}) {
    const {name: serviceName, Icon} = SERVICES[service];
    return <span className="d-flex py-0 flex-row align-items-center">
        <DoctorAvatar doctor={doctor} width={48} className="me-3"/>
        <Icon className="me-2" size="2em"/>
        <div className="d-inline-flex flex-row align-items-center flex-wrap">
            <span className="me-2">{serviceName}</span><Badge pill bg="info"><FaCalendar/> {dateFrom.setLocale('pl').toFormat('cccc (d LLLL)')} {startAt}</Badge>
            <small className="text-muted">{DOCTORS[doctor].name}</small>
        </div>
    </span>;
}

export function Done({detail, service, dateFrom, startAt, doctor, changeSlot}) {
    return <div className="modal show d-block">
        <Modal.Dialog>
            <Modal.Header>
                <VisitTitle {...{service, dateFrom, startAt, doctor}} />
            </Modal.Header>
            <Modal.Body>
                <Alert variant="success">{detail}</Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={changeSlot}><FaCalendarAlt/> Zarezerwuj kolejną wizytę</Button>
            </Modal.Footer>
            <Modal.Footer>
            </Modal.Footer>
        </Modal.Dialog>
    </div>;
}