import pick from 'lodash/pick';
import {useEffect, useReducer} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import './App.css';
import {Confirmation, Done, IS_DEV, SERVICE_IDS, SERVICES, VisitDetails, VisitSlots} from './Widget';

const queryClient = new QueryClient();

function reducerFn(previous, {type, ...action}) {
    const actions = {
        selectService: () => ({...previous, service: action.service}),
        selectSlot: () => ({...previous, state: 'VisitDetails', ...pick(action, 'dateFrom', 'startAt', 'endAt', 'doctor')}),
        changeSlot: () => ({...previous, state: 'VisitSlots'}),
        setDetails: () => ({...previous, ...action}),
        submitVisit: () => ({...previous, state: 'Confirmation',
            ...pick(action, 'jwtToken', 'codeNumber'),
            ...(action.customerId ? {customerId: action.customerId} : {})}),
        confirmVisit: () => ({...previous, state: 'Done', detail: action.detail})
    };

    return actions[type]();
}

function sendGAEvent({state, service, dateFrom, startAt, doctor, customerId}) {
    const item_list_name = SERVICES[service]?.name;
    const details = () => ({
        item_list_name,
        item_list_id: item_list_name,
        items: dateFrom && startAt && doctor ? [{item_name: dateFrom.toISODate(), item_variant: startAt, item_brand: doctor}] : [],
    });
    const events = {
        VisitSlots: () => (['view_item_list', details()]),
        VisitDetails: () => (['select_item', details()]),
        Confirmation: () => (['add_to_cart', details(), (customerId && customerId.toString())]),
        Done: () => (['purchase', details()])
    };
    const evt = events[state]?.();
    if (IS_DEV) {
        console.debug('Skipping GA event', evt);
        return;
    }

    try {
        console.log('postMessage', evt)
        evt && window.parent.postMessage(evt, 'https://www.floraldent.pl');
    } catch (e) {
        console.error("Nie można wysłać eventu GA do strony macierzystej")
    }
}

function Widget() {
    const [state, dispatch] = useReducer(reducerFn, {state: 'VisitSlots', service: SERVICE_IDS.CHILD});

    const selectSlot = (slot) => dispatch({type: 'selectSlot', ...slot}),
        selectService = (service) => dispatch({type: 'selectService', service}),
        setDetails = (details) => dispatch({type: 'setDetails', ...details}),
        submitVisit = (jwtToken, codeNumber = 1, customerId) => dispatch({type: 'submitVisit', jwtToken, codeNumber, customerId}),
        changeSlot = () => dispatch({type: 'changeSlot'}),
        confirmVisit = (detail) => dispatch({type: 'confirmVisit', detail});

    // Trigger effect only on change of state or service.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => sendGAEvent(state), [state.state, state.service, state.customerId]);

    switch (state.state) {
        case 'VisitDetails':
            return <VisitDetails {...state} submitVisit={submitVisit} setDetails={setDetails} changeSlot={changeSlot}/>;
        case 'Confirmation':
            return <Confirmation {...state} changeSlot={changeSlot} confirmVisit={confirmVisit} setNewToken={submitVisit}/>;
        case 'Done':
            return <Done {...state} changeSlot={changeSlot}/>;
        case 'VisitSlots':
        default:
            return <VisitSlots {...state} selectSlot={selectSlot} selectService={selectService}/>;
    }
}

function App() {
    return <QueryClientProvider client={queryClient}>
        <Widget/>
    </QueryClientProvider>;
}

export default App;
